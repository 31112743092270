/*
 * @Description: 全局过滤器
 */

/* 导出时间格式函数 */
export { parseTime, formatTime } from '@/utils'

/**
 * 数字缩写
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits 保留几位小数
 *
 * {{ 10000 | numberFormatter }}
 * {{ 100000.6666 | numberFormatter(3) }}
 */
export function numberFormatter (num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 数字三位切割
 * 10000 => "10,000"
 * @param {number} num
 *
 * {{ 100000.6666 | toThousandFilter }}
 */
export function toThousandFilter (num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * 首字母大写
 * @param {String} string
 *
 * {{ capital letter | uppercaseFirst }}
 */
export function uppercaseFirst (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * 获取距离指定时间还有多少天
 * @param {String | Number | Date} dateTime 日期时间
 * @example
 * ```javascript
 *     getDistanceSpecifiedTime('2019/02/02 02:02:00');
 *     getDistanceSpecifiedTime(1549036800000);
 *     getDistanceSpecifiedTime(new Date("2019/2/2 00:00:00"));
 * ```
 */
export function remainingDays (dateTime) {
  const nowDate = new Date()
  const date = (new Date(dateTime).getTime() - nowDate.getTime()) / (1000 * 60 * 60 * 24)
  return parseInt(date) + 1
}
