// 主页接口
import http from '../request.js'

export default {
  // 会员充值类目
	getConfig: data => {
    return http({
      url: '/api/recharge/getconfig',
      data
    })
	},

  // Web充值
  Webadd: data => {
    return http({
      url: '/api/recharge/add',
      data
    })
  },

  // 查询Web充值状态
  getWebPay: data => {
    return http({
      url: '/api/recharge/get',
      data
    })
  },
}
