<template>
  <div class="recommended">
    <div class="content">
      <div class="content_header">
        <div style="display: flex; align-items: center">
          <!-- <i
            class="iconfont icon-xiangyan"
            style="color: #aaaaaa; font-size: 20px"
          ></i> -->
          <img
            style="width: 14px; margin-right: 6px"
            src="../../assets/icon/yan.png"
            alt=""
            srcset=""
          />
          今日异动
        </div>

        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          menu-trigger="click"
          active-text-color="#d83e26"
          @select="handleSelect"
        >
          <el-menu-item index="全部">全部</el-menu-item>
          <el-submenu index="2">
            <template slot="title">{{ factoryTitle }}</template>
            <el-menu-item
              v-for="(item, index) in factoryList"
              :key="index"
              :index="item"
            >
              {{ item }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="酒品">酒品</el-menu-item>
        </el-menu>
      </div>

      <div class="content_center">
        <div
          class="product_item"
          v-for="(item, index) in changeList"
          :key="index"
          @click.stop="goDetails(item.Title)"
        >
          <!-- <div class="product_item_name">{{ item.Title }}</div>
          <div class="product_item_price">
            <div>
              行情参考:
              <span :class="item.classColor">
                ￥{{ item.value }}
                <span v-if="item.classColor == 'red'">↑</span>
                <span v-else>↓</span>
                {{ item.changeValue1 }}
              </span>
            </div>
          </div> -->
          <img
            class="product_item_img"
            v-if="item.ImgUrl"
            :src="
              item.ImgUrl.indexOf('cosgz') > -1
                ? item.ImgUrl.replace('cosgz', 'cos.ap-guangzhou') + '/pc_img'
                : item.ImgUrl
            "
            alt=""
          />
          <img
            class="product_item_img"
            v-else
            src="../../assets/img/noImg.png"
            alt=""
          />
          <div class="product_item_name">{{ item.Title }}</div>
          <div class="product_item_price">
            <div>
              行情参考:
              <span :class="item.classColor">
                ￥{{ item.value }}
                <span v-if="item.classColor == 'red'">↑</span>
                <span v-else>↓</span>
                {{ item.changeValue1 }}
              </span>
            </div>
            <!-- <div class="details">详情 ></div> -->
          </div>
        </div>

        <div class="no_list" v-if="changeList.length < 1">
          <div class="text-center">
            <img
              class="no_list_img"
              src="../../assets/img/noList.png"
              alt=""
              srcset=""
            />
            <div style="margin-top: 15px">暂无数据</div>
            <!-- <div style="margin-top: 10px; font-size: 12px; color: #999999">
              请查看其他吧~
            </div> -->
          </div>
        </div>
      </div>

      <div class="advertising">
        <img
          class="advertising_img"
          src="../../assets/img/advertising.png"
          alt=""
        />
      </div>

      <div class="content_bottom">
        <div class="content_bottom_header">
          <div ref="merchants">
            <i
              class="iconfont icon-cooperation-full"
              style="color: #aaaaaa; font-size: 20px; margin-right: 6px"
            ></i>
            <span>商户社交</span>
            <span> | 行业互通、资讯共享、文化交流</span>
          </div>

          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="关注" name="关注"></el-tab-pane>
              <el-tab-pane label="推荐" name="推荐"></el-tab-pane>
              <el-tab-pane
                :label="province.replace('省', '')"
                :name="province"
              ></el-tab-pane>
              <el-tab-pane
                :label="city.replace('市', '')"
                :name="city"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <div class="release">
          <div
            class="release_item"
            v-for="(item, index) in releaseList"
            :key="index"
          >
            <div
              v-if="activeName != '推荐'"
              class="release_item_merchants"
              @click.stop="goMerchants(item.UserId)"
            >
              <img
                class="merchants_img"
                :src="item.StoreHeadImage || defaultPic"
                alt=""
              />
              <span>{{ item.StoreName || '--' }}</span>
              <img
                v-if="item.UserId == 44"
                class="merchants_icon"
                src="../../assets/icon/official.png"
                alt=""
              />
              <img
                v-else-if="item.CertificationType"
                class="merchants_icon2"
                src="../../assets/icon/certification.png"
                alt=""
              />
            </div>

            <div
              class="release_item_title"
              @click.stop="goArticleDetails(item.Id)"
            >
              {{ item.Title }}
            </div>

            <div
              class="release_item_imgs"
              @click.stop="goArticleDetails(item.Id)"
            >
              <img
                v-for="(items, index) in item.image.slice(0, 4)"
                :key="index"
                class="imgs_item"
                :src="
                  items.Image.indexOf('cosgz') > -1
                    ? items.Image.replace('cosgz', 'cos.ap-guangzhou') +
                      '/pc_img'
                    : items.Image
                "
                alt=""
              />
            </div>

            <div
              v-if="activeName == '推荐'"
              class="release_item_author"
              @click.stop="goMerchants(item.UserId)"
            >
              <span style="color: #2280ff">发布者</span>
              <span style="margin: 0 6px">{{ item.StoreName || '' }}</span>
              <span style="color: #9a9a9a">{{
                new Date(item.ReleaseTime) | formatTime()
              }}</span>
            </div>

            <div v-else class="release_item_footer">
              <span>{{ new Date(item.UpdateTime) | formatTime }}</span>
              <span
                style="color: #2280ff; cursor: pointer"
                @click="sendMsg(index)"
                >给Ta留言</span
              >
              <i
                v-if="codeImgCurrent == index"
                class="el-icon-close code_close"
                @click="codeImgCurrent = null"
              ></i>
              <img
                v-if="codeImgCurrent == index"
                class="release_item_footer_img"
                src="../../assets/img/code3.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="offer">
      <div class="offer_header">
        <div>今日报价</div>
        <div class="offer_date">
          <el-date-picker
            v-model="date"
            type="date"
            :clearable="false"
            :editable="false"
            @change="listChange"
          ></el-date-picker>
        </div>
      </div>

      <div class="offer_price">
        <div class="offer_price_header">
          <div
            class="offer_price_header_item"
            :class="currentIndex == 0 ? 'active' : ''"
            @click="currentIndexChange(0)"
          >
            今日酒市
          </div>
          <div
            class="offer_price_header_item"
            :class="currentIndex == 1 ? 'active' : ''"
            @click="currentIndexChange(1)"
          >
            今日行情
          </div>
        </div>

        <div class="offer_price_title">
          <div style="flex: 3">名称</div>
          <div style="flex: 1">今日</div>
          <div style="flex: 1">涨跌</div>
        </div>

        <div
          class="offer_price_item"
          v-for="(item, index) in offerList"
          :key="item.Id"
          @click="setChart(index, item.Title)"
        >
          <div class="offer_price_items">
            <div class="item_name">{{ item.Title }}</div>
            <div style="flex: 1" :class="item.classColor">{{ item.value }}</div>
            <div style="flex: 1" :class="item.classColor">
              <span v-if="item.classColor == 'red'">↑</span>
              <span v-else>↓</span>
              {{ item.changeValue1 }}
            </div>
          </div>

          <div class="myChart" v-if="chartIndex == index">
            <div
              :id="'myCharts' + index"
              style="width: 268px; height: 130px"
            ></div>
          </div>
        </div>

        <div class="view_more" @click="viewMore">查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Recommended',
  props: {
    // 烟酒异动数据
    smokeAllList: {
      type: Array,
      default: () => [],
    },
    wineAllList: {
      type: Array,
      default: () => [],
    },
    // 烟厂列表
    factoryList: {
      type: Array,
      default: () => [],
    },
    // 商户发布列表
    releaseList: {
      type: Array,
      default: () => [],
    },
    province: {
      type: String,
      default: '广东省',
    },
    city: {
      type: String,
      default: '汕头市',
    },
  },
  data() {
    return {
      defaultPic: 'https://api.chajiuqu.com/uploadImage/img/defaultPict.png',

      activeIndex: '全部',
      activeName: '推荐',

      codeImgCurrent: null,

      changeList: [],
      smokeList: [],
      offerList: [],

      date: this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}'),

      currentIndex: 0,

      chartIndex: 0,

      factoryTitle: '香烟',
    }
  },
  watch: {
    smokeAllList: function (newVal) {
      this.changeList = newVal.slice(0, 4)
    },
    wineAllList: function (newVal) {
      const arr = newVal.slice(0, 4)
      this.changeList.push(...arr)

      this.offerList = newVal.slice(0, 10)
      // console.log(this.offerList);

      this.$nextTick(() => {
        if (!this.offerList.length) return
        this.getChartData(this.offerList[0].Title)
      })
    },
  },
  methods: {
    // 今日异动选择
    handleSelect(key) {
      if (!this.$store.state.tokens) {
        this.$router.push({
          path: '/login',
        })
        return
      }

      if (
        this.$store.state.tokens &&
        this.$globalFun.remainingDays(this.$store.state.info.VipTime) < 1
      ) {
        this.$confirm('您的VIP已到期，请续费查看', '尊敬的准VIP会员：', {
          confirmButtonText: '续费',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$emit('checkRoute', true)
          })
          .catch(() => {
            // next()
            console.log('取消')
          })

        return
      }

      if (key == this.activeIndex) return
      // console.log(key, this.activeIndex);
      this.activeIndex = key
      if (key == '全部') {
        this.changeList = this.smokeAllList
          .slice(0, 4)
          .concat(this.wineAllList.slice(0, 4))
      } else if (key == '酒品') {
        this.changeList = this.wineAllList.slice(0, 8)
      } else {
        this.factoryTitle = key
        const arr = this.smokeAllList.filter((item) => {
          return item.Factory == key
        })
        this.changeList = arr.slice(0, 8)
      }
    },

    // 文章类选择
    handleClick() {
      console.log('changeRelease', this.activeName)
      this.$emit('changeRelease', this.activeName)
    },

    // 商品跳转详情
    goDetails(title) {
      if (
        this.$store.state.tokens &&
        this.$globalFun.remainingDays(this.$store.state.info.VipTime) < 1
      ) {
        this.$confirm('您的VIP已到期，请续费查看', '尊敬的准VIP会员：', {
          confirmButtonText: '续费',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$emit('checkRoute', true)
          })
          .catch(() => {
            // next()
            console.log('取消')
          })
      } else {
        this.$router.push({
          path: '/details',
          query: {
            title,
          },
        })
      }
    },

    // 留言
    sendMsg(index) {
      if (this.codeImgCurrent == index) {
        this.codeImgCurrent = null
      } else {
        this.codeImgCurrent = index
      }
    },

    // 跳转商家
    goMerchants(id) {
      if (
        this.$store.state.tokens &&
        this.$globalFun.remainingDays(this.$store.state.info.VipTime) < 1
      ) {
        this.$confirm('您的VIP已到期，请续费查看', '尊敬的准VIP会员：', {
          confirmButtonText: '续费',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$emit('checkRoute', true)
          })
          .catch(() => {
            // next()
            console.log('取消')
          })
      } else {
        this.$router.push({
          path: '/merchants/index',
          query: {
            id,
          },
        })
      }
    },

    // 文章详情
    goArticleDetails(id) {
      if (
        this.$store.state.tokens &&
        this.$globalFun.remainingDays(this.$store.state.info.VipTime) < 1
      ) {
        this.$confirm('您的VIP已到期，请续费查看', '尊敬的准VIP会员：', {
          confirmButtonText: '续费',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$emit('checkRoute', true)
          })
          .catch(() => {
            // next()
            console.log('取消')
          })
      } else {
        this.$router.push({
          path: '/merchants/details',
          query: {
            id,
          },
        })
      }
    },

    // 查看更多
    viewMore() {
      const path = this.currentIndex == 0 ? 'wine' : '/smoke'

      if (
        this.$store.state.tokens &&
        this.$globalFun.remainingDays(this.$store.state.info.VipTime) < 1
      ) {
        this.$confirm('您的VIP已到期，请续费查看', '尊敬的准VIP会员：', {
          confirmButtonText: '续费',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$emit('checkRoute', true)
          })
          .catch(() => {
            // next()
            console.log('取消')
          })
      } else {
        this.$router.push({
          path,
        })
      }
    },

    // 右侧报价选项卡切换
    listChange() {
      this.getChangeList(
        this.$globalFun.parseTime(new Date(this.date), '{y}-{m}-{d}'),
        this.currentIndex
      )
    },
    currentIndexChange(index) {
      if (this.currentIndex == index) return
      this.currentIndex = index

      this.getChangeList(
        this.$globalFun.parseTime(new Date(this.date), '{y}-{m}-{d}'),
        index
      )
    },
    // 数据切换
    async getChangeList(date, type) {
      // console.log(date, type);

      let data = null
      let form = {
        pageindex: 1,
        category1: '全部',
        date,
        type: 0,
        isChange: 1,
        isLogin: false,
      }

      if (type == 0) {
        form.category1 = '国酒'
        const { data: data1 } = await this.$api.offer.getList(form)

        form.category1 = '国酒'
        const { data: data2 } = await this.$api.offer.getList(form)

        data = data1.list.concat(data2.list)
      } else {
        const { data: res } = await this.$api.offer.getList(form)
        data = res.list
      }

      let list = []
      if (data) {
        for (const i of data) {
          list.push(...i.foods)
        }
      }
      list = list.slice(0, 10)

      for (const arr of list) {
        let classColor = ''
        let value = 0
        let changeValue = 0

        if (
          arr.OfferValue &&
          arr.RetrieveValue &&
          arr.OfferValue !== arr.RetrieveValue
        ) {
          classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
          value = arr.OfferValue
          changeValue = this.$globalFun.numFormat(
            arr.OfferValue - arr.RetrieveValue
          )
        } else if (
          arr.ZSJValue &&
          arr.ZSJRetrieveValue &&
          arr.ZSJValue !== arr.ZSJRetrieveValue
        ) {
          classColor = this.riseAndFall(arr.ZSJValue, arr.ZSJRetrieveValue)
          value = arr.ZSJValue
          changeValue = this.$globalFun.numFormat(
            arr.ZSJValue - arr.ZSJRetrieveValue
          )
        } else if (
          arr.SZValue &&
          arr.SZRetrieveValue &&
          arr.SZValue !== arr.SZRetrieveValue
        ) {
          classColor = this.riseAndFall(arr.SZValue, arr.SZRetrieveValue)
          value = arr.SZValue
          changeValue = this.$globalFun.numFormat(
            arr.SZValue - arr.SZRetrieveValue
          )
        } else {
          classColor = this.riseAndFall(arr.SZValue2, arr.SZRetrieveValue2)
          value = arr.SZValue2
          changeValue = this.$globalFun.numFormat(
            arr.SZValue2 - arr.SZRetrieveValue2
          )
        }

        arr.classColor = classColor
        arr.value = value
        arr.changeValue1 = changeValue - 0
      }

      this.offerList = list

      this.chartIndex = 0
      this.$nextTick(() => {
        if (!this.offerList.length) return
        this.getChartData(this.offerList[0].Title)
      })
    },

    // 走势图数据设置
    async setChart(index, title) {
      if (this.chartIndex == index) return
      this.chartIndex = index

      // console.log(this.chartIndex);

      this.$nextTick(() => {
        this.getChartData(title)
      })
    },

    // 判断涨跌
    riseAndFall(newV, oldV) {
      const num = newV - oldV

      if (num > 0) {
        return 'red'
      } else if (num === 0) {
        return ''
      } else {
        return 'green'
      }
    },

    // 获取走势图数据
    async getChartData(title) {
      const id = 'myCharts' + this.chartIndex

      const { data } = await this.$api.offer.getDateList({
        pageindex: 1,
        title,
        date: this.$globalFun.parseTime(new Date(this.date), '{y}-{m}-{d}'),
        isLogin: false,
      })
      console.log('1111', data)

      let xData = []
      let yData = []

      data.forEach((item) => {
        xData.unshift(this.$globalFun.parseTime(new Date(item.Date), '{m}/{d}'))
        yData.unshift(item.OfferValue || 0)
      })

      this.setEcharts(id, xData.slice(-7), yData.slice(-7), this.currentIndex)
    },

    // 走势图配置设置
    setEcharts(id, xData, yData, type) {
      if (!document.getElementById(id)) return
      const myChart = echarts.init(document.getElementById(id))

      let option = {
        title: {
          show: false,
          text: 'ECharts图表',
        },
        legend: {
          show: false,
        },
        grid: {
          left: 10,
          top: 20,
          right: 40,
          bottom: 30,
          borderColor: '#eeeeee',
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisLabel: {
            interval: 0,
            fontSize: 10,
            rotate: 30,
          },
        },
        yAxis: {
          type: 'value',
          position: 'right',
          max: this.$globalFun.max(yData) + (type == 1 ? 2 : 20),
          min: this.$globalFun.min(yData) - (type == 1 ? 5 : 50),
        },
        color: ['#DD3B2E'],
        tooltip: {},
        series: [
          {
            data: yData,
            type: 'line',
            symbol: 'circle',
            symbolSize: 5,
          },
        ],
      }

      option && myChart.setOption(option)
    },
  },
}
</script>

<style scoped lang="scss">
.recommended {
  width: 1090px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .red {
    color: #d83e26;
  }

  .green {
    color: #359f1f;
  }

  .content {
    width: 745px;
    text-align: left;

    .content_header {
      width: 100%;
      padding-bottom: 13px;
      border-bottom: 1px solid #e5e5e5;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      line-height: 25px;
      color: #000000;
      display: flex;

      /deep/ .el-menu--horizontal {
        border-bottom: none !important;
      }

      /deep/ .el-menu--horizontal .el-menu-item,
      /deep/ .el-menu--horizontal .el-submenu .el-submenu__title {
        height: 25px;
        line-height: 25px;
        font-size: 16px;
        color: #353535;
        font-family: Microsoft YaHei;
        font-weight: 600;
        transition: none;
      }

      /deep/ .el-menu.el-menu--horizontal {
        border-bottom: none;
      }

      /deep/ .el-menu--horizontal .is-active {
        border-bottom: none !important;
        color: #d83e26;
      }

      /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
        border-bottom: none !important;
        color: #d83e26;
      }

      /deep/ .el-menu--horizontal .el-menu--popup {
        max-width: 500px;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .content_center {
      width: 100%;
      height: 472px;
      display: flex;
      flex-wrap: wrap;

      .no_list {
        width: 100%;
        height: 472px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;

        .no_list_img {
          width: 69px;
          height: 96px;
          display: block;
          margin: 0 auto;
        }
      }

      .product_item {
        width: 175px;
        margin-top: 12px;
        margin-left: 15px;
        cursor: pointer;

        .product_item_name {
          width: 175px;
          margin-top: 7px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 700;
          color: #000002;
        }

        .product_item_img {
          width: 173px;
          height: 173px;
          margin-top: 7px;
          display: block;
          border: 1px solid #e3e3e3;
        }

        .product_item_price {
          width: 175px;
          margin-top: 7px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000002;
          display: flex;
          justify-content: space-between;

          .details {
            color: #bcbcbc;
          }
        }
      }

      .product_item:nth-child(4n + 1) {
        margin-left: 0;
      }
    }

    .advertising {
      width: 745px;
      height: 168px;
      margin-top: 32px;
      background: rgba(0, 0, 0, 0.39);
      font-size: 32px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .advertising_img {
        width: 745px;
        height: 168px;
        display: block;
      }
    }

    .content_bottom {
      width: 745px;
      margin-top: 20px;

      .content_bottom_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;

        /deep/ .el-tabs__item {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
        }

        /deep/ .el-tabs__item:hover {
          color: #d83e26;
        }

        /deep/ .el-tabs__item.is-active {
          color: #d83e26;
        }

        /deep/ .el-tabs__active-bar {
          background-color: #d83e26;
        }

        /deep/ .el-tabs__header {
          margin: 0 !important;
        }

        /deep/ .el-tabs__nav-wrap::after {
          height: 1px;
          background-color: #e5e5e5;
        }
      }

      .release {
        width: 100%;

        .release_item {
          width: 100%;
          margin-top: 20px;
          padding-bottom: 8px;
          border-bottom: 5px solid #f2f2f2;
          cursor: pointer;

          .release_item_merchants {
            width: 100%;
            margin-bottom: 16px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 600;
            color: #000000;
            display: flex;
            align-items: center;

            .merchants_img {
              width: 25px;
              height: 25px;
              display: block;
              margin-right: 5px;
              border-radius: 50%;
            }

            .merchants_icon {
              width: 91px;
              height: 20px;
              display: block;
              margin-left: 5px;
            }

            .merchants_icon2 {
              width: 83px;
              height: 21px;
              display: block;
              margin-left: 5px;
            }
          }

          .release_item_title {
            width: 100%;
            min-height: 34px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 600;
            line-height: 26px;
            color: #000000;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .release_item_imgs {
            width: 100%;
            height: 175px;
            margin-top: 5px;
            display: flex;
            align-items: center;

            .imgs_item {
              width: 175px;
              height: 175px;
              display: block;
              margin-left: 15px;
            }

            .imgs_item:first-child {
              margin-left: 0;
            }
          }

          .release_item_author {
            margin-top: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #848484;
          }

          .release_item_footer {
            width: 100%;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #9a9a9a;
            position: relative;

            .code_close {
              font-size: 24px;
              color: #000000;
              position: absolute;
              bottom: 226px;
              right: -190px;
              z-index: 999;
              cursor: pointer;
            }

            .release_item_footer_img {
              width: 200px;
              height: 236px;
              background-color: #fff;
              position: absolute;
              bottom: 20px;
              right: -200px;
              z-index: 996;
            }
          }
        }
      }
    }
  }

  .offer {
    width: 310px;
    height: 800px;
    position: sticky;
    top: 20px;

    .offer_header {
      width: 100%;
      height: 40px;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .offer_date {
        width: 130px;
        height: 32px;

        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 130px !important;
        }

        /deep/ .el-input__inner {
          width: 130px !important;
          height: 32px !important;
          background-color: #6d6d6d !important;
          color: #fff !important;
          font-size: 15px;
        }

        /deep/ .el-input__icon {
          line-height: 32px !important;
          color: #fff !important;
        }

        /deep/ .el-input--suffix .el-input__inner {
          padding-right: 0 !important;
        }
      }
    }

    .offer_price {
      width: 308px;
      height: 698px;
      border: 1px solid #e6e6e6;

      .offer_price_header {
        width: 100%;
        height: 38px;
        background: linear-gradient(180deg, #ffffff 0%, #f4f6f8 100%);
        display: flex;
        align-items: center;

        .offer_price_header_item {
          width: 95px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #353535;
          cursor: pointer;
        }

        .active {
          height: 36px;
          background-color: #fff;
          border-top: 2px solid #d84028;
        }
      }

      .offer_price_title {
        width: calc(100% - 40px);
        height: 40px;
        padding: 0 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #5d5d5d;
        display: flex;
        align-items: center;
        text-align: left;
      }

      .offer_price_item {
        width: calc(100% - 40px);
        padding: 0 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #5d5d5d;
        border-bottom: 1px solid #f4f6f8;

        .offer_price_items {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          text-align: left;
          cursor: pointer;

          .item_name {
            flex: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .myChart {
          width: 268px;
          height: 130px;
        }
      }

      .view_more {
        width: 279px;
        height: 46px;
        margin: 20px auto 0;
        background: rgba(255, 255, 255, 1);
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        color: #525252;
        cursor: pointer;
      }
    }
  }
}
</style>
