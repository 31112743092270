// 主页接口
import http from '../request.js'

export default {
  // 登录
  login: (data) => {
    return http({
      url: '/api/user/login',
      data,
    })
  },

  // 退出
  logout: (data) => {
    return http({
      url: '/api/user/loginout',
      data,
    })
  },

  // 获取个人信息
  getInfo: (data) => {
    return http({
      url: '/api/user/get',
      data,
    })
  },

  // 判断手机号是否已注册
  checkPhone: (data) => {
    return http({
      url: '/api/user/checkPhone',
      data,
    })
  },

  // 获取验证码
  getCode: (data) => {
    return http({
      url: '/api/user/verification_code',
      data,
    })
  },

  // 获取粉丝关注统计
  getStoreCount: (data) => {
    return http({
      url: '/api/userarticle/storecount',
      data,
    })
  },

  // 获取关注商家，未关注则为空
  getCollect: (data) => {
    return http({
      url: '/api/usercollection/get',
      data,
    })
  },

  // 添加关注
  addCollect: (data) => {
    return http({
      url: '/api/usercollection/add',
      data,
    })
  },

  // 取消关注
  delCollect: (data) => {
    return http({
      url: '/api/usercollection/delete',
      data,
    })
  },

  // 用户发布文章
  articleListUser: (data) => {
    return http({
      url: '/api/userarticle/article_list_user',
      data,
    })
  },

  // 文章详情
  getArticleInfo: (data) => {
    return http({
      url: '/api/userarticle/article_get',
      data,
    })
  },
}
