<template>
  <div>
    <div class="header">
      <div style="display: flex; align-items: center">
        <img
          class="logo_img"
          src="../../assets/img/loginLogo1.png"
          alt=""
          srcset=""
          @click="$router.push({ path: '/home' })"
        />

        <div class="lines"></div>
        <img class="logo_img3" src="../../assets/img/loginLogo3.png" alt="" />

        <div
          class="tab_list"
          v-for="(item, index) in tabList"
          :key="item.name"
          @click="$router.push({ path: item.path })"
        >
          {{ item.name }}
          <div
            class="update_status"
            v-if="(smokeFlag && index == 1) || (wineFlag && index == 2)"
          >
            今天已更新
          </div>
        </div>
      </div>

      <!-- <div class="search_box">
        <div class="search_input">
          <el-input
            v-model="inputVal"
            placeholder="请输入关键字查一查"
          ></el-input>
        </div>

        <div class="search_icon">
          <i class="el-icon-search search_icon"></i>
        </div>
      </div> -->

      <div style="display: flex; align-items: center">
        <img
          class="logo_img2"
          src="../../assets/img/loginLogo2.png"
          alt=""
          srcset=""
          @click="goConsortium"
        />

        <div class="login">
          <img
            class="icon_my"
            @click="goMerchants(info.Id)"
            :src="info.StoreHeadImage || defaultPic"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MerchantsHeader',
  data() {
    return {
      info: JSON.parse(localStorage.getItem('info') || null),
      defaultPic: 'https://api.chajiuqu.com/uploadImage/img/defaultPict.png',
      tabList: [
        {
          name: '首页',
          path: '/home',
        },
        {
          name: '今日行情',
          path: '/smoke',
        },
        {
          name: '今日酒市',
          path: '/wine',
        },
        {
          name: '商品发布',
          path: '/release',
        },
      ],

      inputVal: '',

      smokeFlag: false,
      wineFlag: false,
    }
  },

  created() {
    this.getUpdate()
  },

  methods: {
    // 跳转新商盟
    goConsortium() {
      window.open('https://xinshangmeng.com/xsm6/')
    },

    goMerchants(id) {
      if (this.$route.path !== '/merchants/index') {
        this.$router.push({
          path: '/merchants/index',
          query: {
            id,
          },
        })
      }
    },

    // 获取更新状态
    async getUpdate() {
      const { data: res1 } = await this.$api.index.getUpdate({
        type: 1,
      })

      const { data: res2 } = await this.$api.index.getUpdate({
        type: 2,
      })

      this.smokeFlag =
        this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}') ==
        this.$globalFun.parseTime(new Date(res1.UpdateTime), '{y}-{m}-{d}')
      this.wineFlag =
        this.$globalFun.parseTime(new Date(), '{y}-{m}-{d}') ==
        this.$globalFun.parseTime(new Date(res2.UpdateTime), '{y}-{m}-{d}')
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 1126px;
  height: 62px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ededf2;

  .logo_img {
    width: 126px;
    height: 37px;
    display: block;
    cursor: pointer;
  }

  .lines {
    width: 0px;
    height: 40px;
    margin: 0 10px 0 26px;
    border: 1px solid #e6e6e6;
  }

  .logo_img3 {
    height: 62px;
    display: block;
  }

  .logo_img2 {
    width: 133px;
    height: 28px;
    display: block;
    margin-left: 20px;
    cursor: pointer;
  }

  .tab_list {
    margin-left: 60px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #000000;
    cursor: pointer;
    position: relative;

    .update_status {
      width: 70px;
      height: 19px;
      background: rgba(250, 155, 43, 1);
      border: 1px solid #ffffff;
      border-radius: 10px 10px 10px 0px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: #ffffff;
      position: absolute;
      top: -18px;
      right: -56px;
    }
  }

  .search_box {
    margin-left: 40px;
    width: 328px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    display: flex;

    .search_input {
      flex: 1;

      /deep/ .el-input__inner {
        border: none !important;
      }
    }

    .search_icon {
      width: 42px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #d83e26;
    }
  }

  .login {
    display: flex;
    align-items: center;
    margin-left: 18px;

    .icon_my {
      width: 28px;
      height: 28px;
      display: block;
      cursor: pointer;
    }
  }
}
</style>
