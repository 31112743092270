// 主页接口
import http from "../request.js"

export default {
	// 获取报价列表
	getList: (data) => {
		return http({
			url: "/api/alcoholoffer/list",
			data
		})
	},

	// 详情
	getInfo: (data) => {
		return http({
			url: "/api/alcoholoffer/info",
			data
		})
	},

	// 获取新闻列表
	getNewsList: (data) => {
		return http({
			url: "/api/news/list",
			data
		})
	},

	// 公告
	getNoticeList: (data) => {
		return http({
			url: "/api/notice/list",
			data
		})
	},

	// 自选列表
	getMainList: (data) => {
		return http({
			url: "/api/alcoholoffer/main",
			data
		})
	},

	// 获取烟厂
	getFactory: (data) => {
		return http({
			url: "/api/product/getfactory",
			data
		})
	},

	// 根据名称和时间查看14天内报价数据
	getDateList: (data) => {
		return http({
			url: "/api/alcoholoffer/datelist",
			data
		})
	},

	// 根据名称查看14周报价数据
	getChart: (data) => {
		return http({
			url: "/api/alcoholoffer/chart_week",
			data
		})
	},
	getChartList: (data) => {
		return http({
			url: "/api/alcoholoffer/chart",
			data
		})
	},

	// 根据名称和时间查看14天内报价数据
	getDatelist: (data) => {
		return http({
			url: "/api/alcoholoffer/datelist",
			data
		})
	},

	// 烟报价分类排序
	addCategorys: (data) => {
		return http({
			url: "/api/user/addCategorys",
			data
		})
	},

	// 根据分类查询数据
	getCategorysList: (data) => {
		return http({
			url: "/api/product/list",
			data
		})
	},

	// 收藏
	collection: (data) => {
		return http({
			url: "/api/alcoholoffer/collection",
			data
		})
	},

	// 烟批量收藏
	collectionSmoke: (data) => {
		return http({
			url: "/api/alcoholoffer/collectionAlcohol",
			data
		})
	}
}
