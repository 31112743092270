import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import router from '../router'
import NProgress from 'nprogress'

/* 创建axios的一个实例 */
var instance = axios.create({
  baseURL: 'https://api.chajiuqu.com',
  // 配置跨域
  // baseURL: '/http',
  // 请求超时毫秒数
  timeout: 10000,
  method: 'post'
})

/* 请求拦截器 */
instance.interceptors.request.use(
  config => {
    // 进度条开启
    NProgress.start()

    // 登陆后请求携带 token
    const token = store.state.tokens
    // console.log(token)
    if (token) {
      config.headers.token = token
    } else {
      store.commit('RESET_STATE')
    }

    return config
  },
  error => {
    // 抛出服务器响应错误
    return Promise.reject(error)
  }
)

/* 响应拦截器 */
instance.interceptors.response.use(
  response => {
    // 进度条关闭
    NProgress.done()

    const res = response.data
    // console.log('请求结果', res)

    // 判断服务器返回的数据
    if (!res.success) {
      if (res.msg === "获取成功") {
        return Promise.resolve(res)
      }

      if (res.msg === '用户不存在！') {
        return Promise.resolve(res)
      }

      Message({
        message: res.msg,
        type: 'error',
        duration: 3 * 1000
      })

      // 未登录/没有权限
      if (res.msg === '请登录') {
        MessageBox.alert(res.msg, '提示', {
          showClose: false,
          showCancelButton: false
        }).then(() => {
          // 登出
          store.dispatch('logout')
          router.push({
            path: '/login'
          })
        })
      }
      if (res.msg === '登录超时') {
        MessageBox.alert(res.msg, '提示', {
          showClose: false,
          showCancelButton: false
        }).then(() => {
          // 登出
          store.dispatch('logout')
          location.reload()
        })
      }

      return Promise.reject(res.msg)
    } else {
      return response.data
    }
  },
  error => {
    // 进度条关闭
    NProgress.done()

    // 抛出服务器响应错误
    Message({
      message: error,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default instance
