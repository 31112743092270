// 主页接口
import http from '../request.js'

export default {
  // 获取报价列表
  getList: (data) => {
    return http({
      url: '/api/alcoholoffer/list',
      data,
    })
  },

  // 获取新闻列表
  getNewsList: (data) => {
    return http({
      url: '/api/news/list',
      data,
    })
  },

  // 用户商圈列表
  getArticleList: (data) => {
    return http({
      url: '/api/userarticle/article_list',
      data,
    })
  },

  // 获取新闻详情
  getNewsInfo: (data) => {
    return http({
      url: '/api/news/get',
      data,
    })
  },

  //获取烟酒报价更新状态
  getUpdate: (data) => {
    return http({
      url: '/api/product/getDrafts',
      data,
    })
  },
}
