<template>
	<div class="header">
		<!-- 登录 -->
		<div class="login_title">
			<div class="title">
				<div>
					欢迎来到茶酒趣平台
					<i class="iconfont icon-jinggao"></i>
					本站非交易平台
				</div>
				<div class="loginInfo" v-if="info">
					<img
						class="loginImg"
						:src="info.StoreHeadImage || defaultPic"
						alt=""
						@click="goMerchants(info.Id)"
					/>
					<span @click="goMerchants(info.Id)" style="cursor: pointer">{{
						info.LoginName
					}}</span>
					<img class="vipImg" src="../../assets/icon/vip.png" alt="" />
					<span>VIP会员</span>
					<span v-if="$globalFun.remainingDays(info.VipTime) > 0">
						期限{{ new Date(info.VipTime) | parseTime("{y}-{m}-{d}") }} (剩余{{
							new Date(info.VipTime) | remainingDays
						}}天)
					</span>
					<span v-else style="color: #d83e26">已过期</span>
					<span class="renewal" @click="getConfig">续期</span>
					<span class="exit" @click="exit">退出</span>
				</div>
				<div v-else>
					<router-link class="login" to="/login">
						<div class="icon_my">
							<i class="iconfont icon-my"></i>
						</div>
						登录/注册
					</router-link>
				</div>
			</div>
		</div>
		<!-- logo -->
		<div class="logo">
			<div style="display: flex; align-items: center">
				<img class="img1" src="../../assets/img/loginLogo1.png" />

				<div class="lines"></div>
				<img class="img2" src="../../assets/img/loginLogo3.png" alt="" />

				<!-- <div class="query_input">
          <div class="search_box">
            <el-input
              v-model="inputKeyWord"
              placeholder="请输入关键字查一查"
            ></el-input>
            <div class="search_box_btn">
              <i class="el-icon-search search_icon"></i>
            </div>
          </div>
          <div style="margin-top: 4px">
            <span>热搜：</span>
            <span
              :class="index != 0 ? 'search_keyword' : ''"
              v-for="(item, index) in moreList"
              :key="index"
              >{{ item }}</span
            >
          </div>
        </div> -->
			</div>
			<div class="logo_2">
				<span>快捷入口：</span>
				<img
					style="width: 170px; height: 36px; cursor: pointer"
					src="../../assets/img/loginLogo2.png"
					@click="goConsortium"
				/>
			</div>
		</div>
		<!-- tabs导航 -->
		<div class="tabs_tit">
			<div class="tabs_content">
				<div class="tabs">
					<div class="tabs_left">
						<div
							class="tabs_item"
							:class="active == index ? 'tabs_item_active' : ''"
							v-for="(item, index) in list"
							:key="item.title"
							@click="onJump(item.path, index)"
						>
							<div class="tabs_item_name">
								<i
									v-if="active == index"
									class="el-icon-caret-right tabs_item_icon"
								></i>
								{{ item.title }}
								<div
									class="update_status"
									v-if="(smokeFlag && index == 1) || (wineFlag && index == 2)"
								>
									今天已更新
								</div>
							</div>
						</div>
					</div>

					<div class="tabs_date" v-if="dateFlag">
						<el-date-picker
							v-model="date"
							type="date"
							value-format="yyyy-MM-dd"
							:clearable="false"
							:editable="false"
							@change="dateChange"
						></el-date-picker>
					</div>
				</div>

				<div>
					<i class="iconfont icon-kefu"></i>
					客服微信:13417003906
				</div>
			</div>
		</div>

		<el-dialog
			title=""
			:visible.sync="topUpDialog"
			width="700px"
			:show-close="false"
			:close-on-click-modal="false"
			:modal="true"
		>
			<transition name="el-fade-in">
				<div class="top_up_dialog">
					<div class="top_up_header">
						<img
							class="top_up_img"
							src="../../assets/img/topUpBg.png"
							alt=""
							srcset=""
						/>
						<div class="top_up_info" v-if="info">
							<img
								class="loginImg"
								:src="info.StoreHeadImage || defaultPic"
								alt=""
							/>
							<span>{{ info.LoginName }}</span>
							<img class="vipImg" src="../../assets/icon/vip.png" alt="" />
							<span style="color: #9f9f9f">VIP会员期限</span>
							<span style="color: #9f9f9f">
								{{ new Date(info.VipTime) | parseTime("{y}-{m}-{d}") }}
							</span>
							<span
								style="color: #9f9f9f"
								v-if="$globalFun.remainingDays(info.VipTime) > 0"
							>
								(剩余{{ new Date(info.VipTime) | remainingDays }}天)
							</span>
						</div>
						<div class="top_up_close" @click.stop="topUpDialogClose">
							<i class="el-icon-close"></i>
						</div>
					</div>

					<div class="top_up_body">
						<div class="config">
							<div
								class="config_item"
								:class="configCurrentIndex == index ? 'config_item_active' : ''"
								v-for="(item, index) in configList"
								:key="item.Id"
								@click.stop="changeCurrentIndex(index)"
							>
								<div class="choose" v-if="configCurrentIndex == index">
									<i class="el-icon-check"></i>
								</div>
								<div class="price">
									<div>
										<div class="price_title">
											{{ configTitleList[item.Id - 1] }}
										</div>
										<div class="price_amount">
											￥<span style="font-size: 39px">{{ item.Price }}</span>
										</div>
										<div class="price_explain">{{ item.Explain }}</div>
									</div>
								</div>
								<div
									class="remark"
									:class="configCurrentIndex == index ? 'remark_active' : ''"
								>
									{{ item.Remark }}
								</div>
							</div>
						</div>

						<div class="qr_code_div">
							<div class="qr_code">
								<div id="qrCode" ref="qrCodeDiv"></div>
							</div>
							<div class="qr_code_instructions">
								<div>
									实付：
									<span style="color: #e74f44; font-size: 32px">{{
										configObj.Price
									}}</span>
									<span style="color: #e74f44; margin-left: 6px">元</span>
									<span style="color: #e74f44; margin-left: 6px"
										>已优惠{{ configObj.OldPrice - configObj.Price }}元</span
									>
								</div>
								<div style="margin-top: 7px">请使用微信扫码支付</div>
								<div style="color: #959595; margin-top: 7px">
									温馨提示：今日行情和历史行情是完全免费的，而成为会员赠送的积分
								</div>
								<div style="color: #959595; margin-top: 2px">
									可以到酒币网兑换商品或抵扣商品的部分费用！支付中遇到问题请加微
								</div>
								<div style="color: #959595; margin-top: 2px">信13417003906</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</el-dialog>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2"
	export default {
		name: "TabHeader",
		props: {
			active: {
				type: Number,
				default: 0
			},
			chooseDate: {
				type: String,
				default: ""
			},
			dateFlag: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				info: JSON.parse(localStorage.getItem("info") || null),
				defaultPic: "https://api.chajiuqu.com/uploadImage/img/defaultPict.png",

				list: [
					{
						title: "首 页",
						path: "/home"
					},
					{
						title: "今日行情",
						path: "/smoke"
					},
					{
						title: "今日酒市",
						path: "/wine"
					},
					{
						title: "商品发布",
						path: "/release"
					},
					{
						title: "商户社交",
						path: "/merchants"
					},
					{
						title: "导出报表",
						path: "/export"
					},
					{
						title: "会员续费",
						path: "topUp"
					}
				],
				inputKeyWord: "",

				moreList: [],

				topUpDialog: false,
				configList: [],
				configObj: {},
				configTitleList: ["包月VIP", "包季VIP", "半年VIP", "包年VIP"],
				configCurrentIndex: 0,
				orderList: [],

				getStateTimer: null,

				date: this.$globalFun.parseTime(new Date(), "{y}-{m}-{d}"),

				smokeFlag: false,
				wineFlag: false
			}
		},

		watch: {
			chooseDate: function (newVal) {
				// console.log(newVal)
				this.date = newVal
			}
		},

		mounted() {
			this.getUpdate()
			this.getNewsInfo()
			if (this.info) {
				this.getInfo(this.info.Id)

				this.orderList = JSON.parse(
					localStorage.getItem("orderList" + this.info.Id) || "[]"
				)
				for (let i = 0; i < this.orderList.length; i++) {
					const time1 = new Date(this.orderList[i].CreateDate).getTime()
					const time2 = new Date().getTime()
					const num = ((time2 - time1) / 1000).toFixed(0)
					// console.log('num', num)
					if (num > 1800) {
						this.orderList.splice(i, 1)
						i -= 1
					}
				}
				localStorage.setItem(
					"orderList" + this.info.Id,
					JSON.stringify(this.orderList)
				)
			}
		},

		methods: {
			// 发现更多
			async getNewsInfo() {
				const { data } = await this.$api.index.getNewsInfo({ Id: 1049 })
				const str = data.Content.slice(4, data.Content.length - 5)
				this.moreList = str.split(",")
			},

			// 个人信息
			async getInfo(Id) {
				const { data } = await this.$api.user.getInfo({ Id })
				// console.log('个人信息', data)
				this.info = data
				this.$store.commit("SET_INFO", data)
			},

			dateChange(e) {
				this.$emit("dateChange", e)
			},

			// 登录退出
			exit() {
				this.$store.dispatch("logout").then(() => {
					this.$message("退出成功")
					this.info = null

					this.$router.push({
						path: "/home"
					})
				})
			},

			// 个人首页
			goMerchants(id) {
				// console.log(id)
				this.$router.push({
					path: "/merchants/index",
					query: {
						id
					}
				})
			},

			// 导航栏切换
			onJump(path, index) {
				console.log(path, index)
				if (this.$route.path === path) return

				if (path === "topUp") {
					console.log(path)
					this.getConfig()
					return
				}

				if (
					this.$store.state.tokens &&
					this.$globalFun.remainingDays(this.info.VipTime) < 1 &&
					![0, 3, 4].includes(index)
				) {
					this.$confirm("您的VIP已到期，请续费查看", "尊敬的准VIP会员：", {
						confirmButtonText: "续费",
						cancelButtonText: "取消",
						type: "warning"
					})
						.then(async () => {
							await this.getConfig()
						})
						.catch(() => {
							console.log("取消")
						})
				} else {
					if (index == 4) {
						if (this.active != 0 || this.$route.path == "/details") {
							this.$router.push({
								path: "/home"
							})
							this.$store.commit("SET_SCROLL", 1)
						}
						this.$emit("merchants")
						return
					}

					this.$router.push({
						path
					})
				}
			},

			// 获取更新状态
			async getUpdate() {
				const { data: res1 } = await this.$api.index.getUpdate({
					type: 1
				})

				const { data: res2 } = await this.$api.index.getUpdate({
					type: 2
				})

				this.smokeFlag =
					this.$globalFun.parseTime(new Date(), "{y}-{m}-{d}") ==
					this.$globalFun.parseTime(new Date(res1.UpdateTime), "{y}-{m}-{d}")
				this.wineFlag =
					this.$globalFun.parseTime(new Date(), "{y}-{m}-{d}") ==
					this.$globalFun.parseTime(new Date(res2.UpdateTime), "{y}-{m}-{d}")
			},

			// 关闭充值窗口
			topUpDialogClose() {
				this.topUpDialog = false
				clearInterval(this.getStateTimer)
			},

			// 获取充值选项
			async getConfig() {
				const { data: res } = await this.$api.recharge.getConfig()
				console.log("获取充值类目", res)

				this.configList = res.reverse()

				this.configObj = this.configList[0]

				this.topUpDialog = true

				this.$nextTick(() => {
					this.changeCurrentIndex(0, true)
				})
			},

			// 选择充值金额
			async changeCurrentIndex(index, flag = false) {
				if (this.configCurrentIndex == index && !flag) return
				this.configCurrentIndex = index

				this.configObj = this.configList[index]

				// document.getElementById('qrCode').innerHTML = ''

				let payCode = ""
				let payId = null
				this.orderList.forEach(async (item) => {
					if (item.ConfigId == this.configObj.Id) {
						payCode =
							"https://api.chajiuqu.com/pay/WxDirect?OrderNo=" + item.OrderNo
						payId = item.Id
					}
				})

				// 创建订单
				if (!payId) {
					const { data: orderRes } = await this.$api.recharge.Webadd({
						configId: this.configObj.Id,
						payType: 1
					})
					console.log("创建订单", orderRes)

					this.orderList.push(orderRes)
					localStorage.setItem(
						"orderList" + this.info.Id,
						JSON.stringify(this.orderList)
					)

					payCode =
						"https://api.chajiuqu.com/pay/WxDirect?OrderNo=" + orderRes.OrderNo
					payId = orderRes.Id
				}

				// console.log('11111111', this.$refs.qrCodeDiv.innerHTML)
				this.$refs.qrCodeDiv.innerHTML = ""

				new QRCode(this.$refs.qrCodeDiv, {
					text: payCode,
					width: 138,
					height: 138,
					colorDark: "#333333", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
				})

				this.getState(payId)
			},

			// 查询是否已支付
			getState(Id) {
				clearInterval(this.getStateTimer)
				this.getStateTimer = setInterval(async () => {
					const { data: state } = await this.$api.recharge.getWebPay({
						Id
					})
					console.log(state)
					if (state.Status > 0) {
						clearInterval(this.getStateTimer)
						this.orderList.forEach(async (item, index) => {
							if (item.ConfigId == Id) {
								this.orderList.splice(index, 1)
							}
						})
						console.log(this.orderList)
						this.topUpDialog = false
					}
				}, 1500)
			},

			// 跳转新商盟
			goConsortium() {
				window.open("https://xinshangmeng.com/xsm6/")
			}
		},

		activated() {
			this.info = JSON.parse(localStorage.getItem("info") || null)
		}
	}
</script>

<style scoped lang="scss">
	.header {
		width: 100%;

		.login_title {
			width: 100%;
			height: 38px;
			background: #f8f8f8;

			.title {
				width: 1126px;
				margin: 0 auto;
				height: 38px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 600;
				color: #3b3b3b;

				.loginInfo {
					display: flex;
					align-items: center;

					.loginImg {
						width: 28px;
						height: 28px;
						background: rgba(0, 0, 0, 0);
						border: 1px solid #e6e6e6;
						border-radius: 50%;
						display: block;
						margin-right: 5px;
						cursor: pointer;
					}

					.vipImg {
						width: 18px;
						height: 18px;
						display: block;
						margin-left: 5px;
						margin-right: 5px;
					}

					.renewal {
						margin-left: 5px;
						color: #d83e26;
						text-decoration: underline;
						cursor: pointer;
					}

					.exit {
						margin-left: 20px;
						color: #797979;
						cursor: pointer;
					}
				}

				.login {
					color: #dd3b2e;
					cursor: pointer;
					display: flex;
					align-items: center;

					.icon_my {
						width: 18px;
						height: 18px;
						margin-right: 4px;
						background: #dd3b2e;
						color: #ffffff;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;

						.iconfont {
							font-size: 14px;
						}
					}
				}
			}
		}

		.logo {
			width: 1126px;
			margin: 0 auto;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000002;

			.img1 {
				width: 197px;
				height: 58px;
				display: block;
			}

			.lines {
				width: 0px;
				height: 40px;
				margin: 0 10px 0 26px;
				border: 1px solid #e6e6e6;
			}

			.img2 {
				width: 153px;
				height: 90px;
				display: block;
			}

			.query_input {
				margin-left: 56px;
				text-align: left;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				line-height: 20px;
				color: #393939;

				.search_box {
					width: 459px;
					height: 40px;
					padding-left: 15px;
					border: 1px solid #d83e26;
					border-radius: 10px;
					display: flex;
					align-items: center;

					.search_box_btn {
						width: 44px;
						height: 40px;
						background: rgba(216, 62, 38, 1);
						color: #ffffff;
						border-radius: 0px 10px 10px 0px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;

						.search_icon {
							font-size: 18px;
						}
					}

					/deep/ .el-input__inner {
						width: 400px;
						height: 40px;
						padding: 0;
						border: none !important;
					}
				}

				.search_keyword {
					margin-left: 14px;
					cursor: pointer;
				}
			}

			.logo_2 {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		.tabs_tit {
			width: 100%;
			height: 40px;
			// background: linear-gradient(180deg, #d83e26 0%, #bf0101 100%);
			background: #9b150a;

			.tabs_content {
				width: 1126px;
				height: 40px;
				margin: 0 auto;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 600;
				color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.tabs {
				flex: 1;
				margin-right: 20px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.tabs_left {
					display: flex;
					align-items: center;
				}

				.tabs_date {
					width: 150px;

					/deep/ .el-date-editor.el-input,
					.el-date-editor.el-input__inner {
						width: 100% !important;
					}

					/deep/ .el-input__inner {
						width: 100% !important;
						height: 40px !important;
						background-color: #9b150a !important;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 600;
						color: #ffffff;
						border: none !important;
					}

					/deep/ .el-input__icon {
						line-height: 40px !important;
						color: #ffffff !important;
					}

					/deep/ .el-input--suffix .el-input__inner {
						padding-right: 0 !important;
					}
				}

				a {
					color: #ffffff;
					text-decoration: none;
				}

				.tabs_item_active {
					background: #791108 !important;
				}

				.tabs_item {
					width: 120px;
					height: 40px;
					line-height: 40px;
					cursor: pointer;

					.tabs_item_name {
						width: auto;
						display: inline-block;
						position: relative;

						.tabs_item_icon {
							position: absolute;
							top: 12px;
							left: -20px;
						}

						.update_status {
							width: 70px;
							height: 19px;
							background: rgba(250, 155, 43, 1);
							border: 1px solid #ffffff;
							border-radius: 10px 10px 10px 0px;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 19px;
							color: #ffffff;
							position: absolute;
							top: -14px;
							right: -56px;
						}
					}
				}
			}
		}

		.top_up_dialog {
			width: 700px;
			// height: 500px;
			padding: 0;

			.top_up_header {
				width: 700px;
				height: 98px;
				display: flex;
				align-items: center;
				position: relative;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;

				.top_up_img {
					width: 700px;
					height: 98px;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
				}

				.top_up_close {
					width: 24px;
					height: 24px;
					background: rgba(165, 0, 0, 1);
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					position: absolute;
					top: -12px;
					right: -12px;
					z-index: 9;
				}

				.top_up_info {
					width: 670px;
					height: 98px;
					padding-left: 30px;
					z-index: 9;
					display: flex;
					align-items: center;

					.loginImg {
						width: 28px;
						height: 28px;
						background: rgba(0, 0, 0, 0);
						border: 1px solid #e6e6e6;
						border-radius: 50%;
						display: block;
						margin-right: 5px;
						cursor: pointer;
					}

					.vipImg {
						width: 18px;
						height: 18px;
						display: block;
						margin-left: 5px;
						margin-right: 5px;
					}
				}
			}

			.top_up_body {
				width: 700px;
				height: 393px;
				background: rgba(245, 246, 250, 1);

				.config {
					padding-top: 7px;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					.config_item {
						width: 149px;
						height: 149px;
						margin-left: 20px;
						background: rgba(255, 255, 255, 1);
						border: 2px solid #ebebeb;
						border-radius: 10px;
						overflow: hidden;
						cursor: pointer;
						position: relative;

						.choose {
							width: 14px;
							height: 14px;
							border-radius: 50%;
							background-color: #af6110;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #fff;
							font-size: 10px;
							position: absolute;
							top: 9px;
							left: 11px;
						}

						.price {
							width: 100%;
							height: 115px;
							display: flex;
							align-items: center;
							justify-content: center;

							.price_title {
								font-size: 15px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #33383e;
							}

							.price_amount {
								font-size: 15px;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #000000;
							}

							.price_explain {
								font-size: 15px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #999fa7;
							}
						}

						.remark {
							width: 149px;
							height: 34px;
							background: #ebebeb;
							line-height: 34px;
							text-align: center;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #7e7e7e;
							position: absolute;
							bottom: 0;
							left: 0;
						}

						.remark_active {
							background: #af6106;
							color: #fff;
						}
					}

					.config_item_active {
						border: 2px solid #af6106;
					}

					.config_item:first-child {
						margin-left: 0 !important;
					}
				}

				.qr_code_div {
					width: 658px;
					height: 195px;
					box-sizing: border-box;
					margin: 0 auto;
					margin-top: 20px;
					padding: 26px 35px 30px 18px;
					background: rgba(255, 255, 255, 1);
					display: flex;
					align-items: center;

					.qr_code {
						width: 138px;
						height: 138px;
					}

					.qr_code_instructions {
						margin-left: 20px;
						flex: 1;
						text-align: left;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #434343;
					}
				}
			}
		}
	}
</style>
