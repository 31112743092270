<template>
  <div class="details">
    <div class="details_img">
      <div>
        <span class="name">{{ detailsInfo.Title }}</span>
        <span class="factory_code" v-if="detailsInfo.Category1 == '全部'">
          代码：{{ detailsInfo.FactoryCode || '待补充' }}
        </span>
      </div>

      <div class="img_border">
        <el-image
          v-if="imgUrl"
          class="img"
          :src="
            imgUrl.indexOf('cosgz') > -1
              ? imgUrl.replace('cosgz', 'cos.ap-guangzhou') + '/pc_img'
              : imgUrl
          "
          fit="contain"
          :preview-src-list="previewImgList"
        ></el-image>

        <img v-else class="img" src="../../assets/img/noImg2.png" alt="" />
      </div>

      <div class="more_img">
        <div
          class="img_item"
          :class="active == index ? 'img_item_active' : ''"
          v-for="(item, index) in imgList"
          :key="index"
          @click="changeImgUrl(index, item)"
        >
          <img
            v-if="item"
            class="imgs"
            :src="
              item.indexOf('cosgz') > -1
                ? item.replace('cosgz', 'cos.ap-guangzhou') + '/pc_img'
                : item
            "
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="details_price">
      <div class="add_optional">
        <div class="optional" @click="addOptional(detailsInfo)">
          <i
            v-if="detailsInfo.collection"
            class="iconfont icon-shoucang star"
            style="color: #ffae4e"
          ></i>
          <i v-else class="iconfont icon-shoucang2 star"></i>
          <span>加入自选</span>
        </div>

        <div class="header_date">
          <el-date-picker
            v-model="date"
            type="date"
            :clearable="false"
            :editable="false"
            @change="listChange"
          ></el-date-picker>
        </div>
      </div>

      <div class="price_contrast">
        <div class="header">
          <div
            style="flex: 1; text-align: left"
            v-if="detailsInfo.Category1 == '全部'"
          >
            区域
          </div>
          <div style="flex: 2" class="text-center">
            <!-- <i class="el-icon-date"></i>
            {{ new Date(detailsInfo.Date) | parseTime('{y}-{m}-{d}') }} -->
            最新
          </div>
          <div style="flex: 1">周同比</div>
          <div style="flex: 1">月同比</div>
          <div style="flex: 1">年同比</div>
          <div style="flex: 1">最高</div>
          <div style="flex: 1">最低</div>
        </div>

        <div
          class="content"
          v-for="(item, index) in priceContrast"
          :key="index"
        >
          <div
            style="flex: 1; text-align: left"
            v-if="detailsInfo.Category1 == '全部'"
          >
            {{ item.area }}
          </div>
          <div style="flex: 2" :class="item.color" class="text-center">
            <span>{{ item.price }}</span>
            <span class="arrow_width" v-if="item.color == 'red'">↑</span>
            <span class="arrow_width" v-else-if="item.color == 'green'">↓</span>
            <span class="arrow_width" v-else>丨</span>
            <span class="price_width">{{ item.changePrice }}</span>
          </div>
          <div style="flex: 1; font-size: 14px" :class="item.weekContrastColor">
            <span class="arrow_width" v-if="item.weekContrastColor == 'red'"
              >↑</span
            >
            <span
              class="arrow_width"
              v-else-if="item.weekContrastColor == 'green'"
              >↓</span
            >
            <span class="price_width">{{ item.weekContrast }}</span>
          </div>
          <div
            style="flex: 1; font-size: 14px"
            :class="item.monthContrastColor"
          >
            <span class="arrow_width" v-if="item.monthContrastColor == 'red'"
              >↑</span
            >
            <span
              class="arrow_width"
              v-else-if="item.monthContrastColor == 'green'"
              >↓</span
            >
            <span class="price_width">{{ item.monthContrast }}</span>
          </div>
          <div style="flex: 1; font-size: 14px" :class="item.yearContrastColor">
            <span class="arrow_width" v-if="item.yearContrastColor == 'red'"
              >↑</span
            >
            <span
              class="arrow_width"
              v-else-if="item.yearContrastColor == 'green'"
              >↓</span
            >
            <span class="price_width">{{ item.yearContrast }}</span>
          </div>
          <div style="flex: 1; font-size: 14px">
            {{ item.maxPrice }}
          </div>
          <div style="flex: 1; font-size: 14px">
            {{ item.minPrice }}
          </div>
        </div>

        <div class="price_contrast_bottom">
          <span>参考零售价：750</span>
          <span>
            <i class="el-icon-warning-outline" style="color: #dbdbdb"></i>
            仅供参考，谢绝交易、推广
          </span>
        </div>
      </div>

      <div class="other_info">
        <div class="other_info_item">
          <span class="left_width">
            {{ detailsInfo.Category1 == '全部' ? '小盒条码' : '瓶装条码' }}：
          </span>
          <span style="color: #000000">{{ detailsInfo.Code || '待补充' }}</span>
        </div>
        <div class="other_info_item">
          <span class="left_width">
            {{ detailsInfo.Category1 == '全部' ? '条盒条码' : '整箱条码' }}：
          </span>
          <span style="color: #000000">
            {{ detailsInfo.BoxCode || '待补充' }}</span
          >
        </div>
        <div class="other_info_item" v-if="detailsInfo.Category1 == '全部'">
          <span class="left_width">单支规格：</span>
          <span style="color: #000000">{{ detailsInfo.Spec || '待补充' }}</span>
        </div>
        <div class="other_info_item" v-else>
          <span class="left_width">包装形式：</span>
          <span style="color: #000000">待补充</span>
        </div>
        <div class="other_info_item">
          <span class="left_width">
            {{ detailsInfo.Category1 == '全部' ? '焦油量' : '净含量' }}：
          </span>
          <span style="color: #000000">待补充</span>
        </div>
        <div class="other_info_item">
          <span class="left_width">
            {{ detailsInfo.Category1 == '全部' ? '烟碱量' : '酒精度' }}：
          </span>
          <span style="color: #000000">待补充</span>
        </div>
        <div class="other_info_item">
          <span class="left_width">
            {{ detailsInfo.Category1 == '全部' ? '一氧化碳量' : '香型' }}：
          </span>
          <span style="color: #000000">待补充</span>
        </div>
      </div>
    </div>

    <div class="details_other">
      <div class="details_other_title">同品牌产品</div>

      <div class="details_other_product">
        <div
          class="product_item"
          v-for="item in otherProductList"
          :key="item.Id"
          @click="changeProduct(item.Name)"
        >
          <span class="product_item_name">{{ item.Name }}</span>
          <span class="product_item_code" v-if="item.Category1 == '全部'">
            代码：{{ item.FactoryCode || '待补充' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsHeader',

  props: {
    detailsInfo: {
      type: Object,
      default: () => {},
    },

    contrastList: {
      type: Array,
      default: () => [],
    },

    otherProductList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    imgList() {
      let imgArr = []
      if (this.detailsInfo.DetailImage) {
        imgArr = this.detailsInfo.DetailImage.split(',')
      }
      return imgArr
    },

    previewImgList() {
      let imgArr = []
      let imgArr2 = []
      let arr = []
      let flag = false
      if (this.detailsInfo.DetailImage) {
        arr = this.detailsInfo.DetailImage.split(',')
      }
      arr.forEach((item) => {
        if (item.indexOf('cosgz.myqcloud.com') > -1) {
          if (item == this.imgUrl || flag) {
            flag = true
            imgArr.push(item.replace('cosgz', 'cos.ap-guangzhou') + '/pro_img')
          } else {
            imgArr2.push(item.replace('cosgz', 'cos.ap-guangzhou') + '/pro_img')
          }
        } else {
          if (item == this.imgUrl || flag) {
            imgArr.push(item)
          } else {
            imgArr2.push(item)
          }
        }
      })
      return imgArr.concat(imgArr2)
    },

    priceContrast() {
      let arr = []
      let list = {}
      // if (!this.contrastList.length) return arr

      this.contrastList.forEach((item) => {
        if (item.Type.indexOf('OfferValue') > -1) {
          if (list['OfferValue']) {
            list['OfferValue'].push(item)
          } else {
            list['OfferValue'] = [item]
          }
        } else if (item.Type.indexOf('ZSJValue') > -1) {
          if (list['ZSJValue']) {
            list['ZSJValue'].push(item)
          } else {
            list['ZSJValue'] = [item]
          }
        } else if (item.Type.indexOf('SZValue2') > -1) {
          if (list['SZValue2']) {
            list['SZValue2'].push(item)
          } else {
            list['SZValue2'] = [item]
          }
        } else if (item.Type.indexOf('SZValue') > -1) {
          if (list['SZValue']) {
            list['SZValue'].push(item)
          } else {
            list['SZValue'] = [item]
          }
        }
      })

      for (let i in list) {
        // console.log(list[i])
        let obj = {}
        if (list[i].length > 3) {
          switch (i) {
            case 'OfferValue':
              obj.area = '流沙'
              obj.price = this.detailsInfo.OfferValue
              obj.changePrice = this.$globalFun.calculate(
                this.detailsInfo.OfferValue,
                this.detailsInfo.RetrieveValue
              )
              obj.color = this.detailsInfo.classColor
              break
            case 'ZSJValue':
              obj.area = '珠三角'
              obj.price = this.detailsInfo.ZSJValue
              obj.changePrice = this.$globalFun.calculate(
                this.detailsInfo.ZSJValue,
                this.detailsInfo.ZSJRetrieveValue
              )
              obj.color = this.detailsInfo.zsjClassColor
              break
            case 'SZValue':
              obj.area = '深圳A'
              obj.price = this.detailsInfo.SZValue
              obj.changePrice = this.$globalFun.calculate(
                this.detailsInfo.SZValue,
                this.detailsInfo.SZRetrieveValue
              )
              obj.color = this.detailsInfo.szClassColor
              break
            case 'SZValue2':
              obj.area = '深圳B'
              obj.price = this.detailsInfo.SZValue2
              obj.changePrice = this.$globalFun.calculate(
                this.detailsInfo.SZValue2,
                this.detailsInfo.SZRetrieveValue2
              )
              obj.color = this.detailsInfo.sz2ClassColor
              break
          }
          list[i].forEach((item) => {
            if (item.Type.indexOf('Week') > -1) {
              obj.weekContrast = Math.abs(item.MaxValue)
              if (item.MaxValue > 0) {
                obj.weekContrastColor = 'red'
              } else if (item.MaxValue < 0) {
                obj.weekContrastColor = 'green'
              } else {
                obj.weekContrastColor = ''
              }
            } else if (item.Type.indexOf('Month') > -1) {
              obj.monthContrast = Math.abs(item.MaxValue)
              if (item.MaxValue > 0) {
                obj.monthContrastColor = 'red'
              } else if (item.MaxValue < 0) {
                obj.monthContrastColor = 'green'
              } else {
                obj.monthContrastColor = ''
              }
            } else if (item.Type.indexOf('Year') > -1) {
              obj.yearContrast = Math.abs(item.MaxValue)
              if (item.MaxValue > 0) {
                obj.yearContrastColor = 'red'
              } else if (item.MaxValue < 0) {
                obj.yearContrastColor = 'green'
              } else {
                obj.yearContrastColor = ''
              }
            } else {
              obj.maxPrice = item.MaxValue
              obj.minPrice = item.MinValue
            }
          })

          arr.push(obj)
        }
      }
      // console.log('arr', arr)
      return arr
    },
  },

  watch: {
    detailsInfo: function (newVal) {
      console.log('newVal', newVal)
      if (newVal.DetailImage) {
        this.imgUrl = newVal.DetailImage.split(',')[0]
        this.date = this.$globalFun.parseTime(
          new Date(newVal.Date),
          '{y}-{m}-{d}'
        )
      } else {
        this.imgUrl = ''
      }
    },
  },

  data() {
    return {
      active: 0,

      imgUrl: '',

      date: '',
    }
  },

  methods: {
    // 图片切换
    changeImgUrl(index, url) {
      this.active = index
      this.imgUrl = url
    },

    // 查看其他产品
    changeProduct(title) {
      this.$emit('changeProduct', title)
    },

    listChange(e) {
      const date = this.$globalFun.parseTime(new Date(e), '{y}-{m}-{d}')
      // console.log('1111111111', e, date)
      this.$emit('changeDate', date)
    },

    // 收藏
    addOptional(item) {
      // console.log(item)
      const form = {
        title: item.Title,
        collection: !item.collection,
      }

      this.$globalFun.throttle(
        () => {
          this.$api.offer
            .collection(form)
            .then(() => {
              this.detailsInfo.collection = !item.collection
              this.$message({
                message: this.detailsInfo.collection ? '收藏成功' : '移除成功',
                type: this.detailsInfo.collection ? 'success' : 'warning',
              })
            })
            .catch(() => {
              this.$message.error('操作失败')
            })
        },
        1000,
        true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.details {
  width: 1126px;
  margin: 15px auto 0;
  display: flex;
  align-items: center;
  text-align: left;

  .details_img {
    width: 327px;

    .name {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #000000;
    }

    .factory_code {
      margin-left: 5px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #d83e26;
    }

    .img_border {
      width: 327px;
      height: 327px;
      margin-top: 13px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        width: 317px;
        height: 317px;
        background: rgba(0, 0, 0, 0);
        display: block;
        cursor: pointer;
      }
    }

    .more_img {
      width: 100%;
      height: 47px;
      margin-top: 17px;
      display: flex;

      .img_item {
        width: 43px;
        height: 43px;
        margin-left: 10px;
        background: rgba(0, 0, 0, 0);
        border: 2px solid #ededed;
        cursor: pointer;

        .imgs {
          width: 43px;
          height: 43px;
          display: block;
        }
      }

      .img_item_active {
        border: 2px solid #d83e26;
      }

      .img_item:first-child {
        margin-left: 0;
      }
    }
  }

  .details_price {
    width: 478px;
    margin-left: 16px;

    .add_optional {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #494949;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .optional {
        cursor: pointer;

        .star {
          font-size: 16px !important;
        }
      }

      .header_date {
        width: 130px;

        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 130px !important;
        }

        /deep/ .el-input__inner {
          width: 130px !important;
          height: 21px !important;
          background-color: #ffffff !important;
          color: #000 !important;
          // border: none !important;
        }

        /deep/ .el-input__icon {
          line-height: 21px !important;
          color: #000 !important;
        }

        /deep/ .el-input--suffix .el-input__inner {
          padding: 10px 20px 10px 30px !important;
          font-size: 15px;
        }
      }
    }

    .price_contrast {
      width: 446px;
      height: 221px;
      padding: 16px;
      margin-top: 20px;
      background: rgba(248, 248, 248, 1);
      border: 1px solid #f2f2f2;
      border-radius: 5px;
      text-align: right;

      .header {
        display: flex;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000;
        padding-bottom: 7px;
        border-bottom: 1px solid #f0f0f0;
      }

      .content {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 700;
        color: #000;
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        align-items: center;

        .arrow_width {
          display: inline-block;
          min-width: 20px;
          text-align: center;
        }

        .price_width {
          display: inline-block;
          // min-width: 20px;
        }
      }

      .price_contrast_bottom {
        margin-top: 7px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6a6a6a;
      }
    }

    .other_info {
      width: 478px;
      height: 123px;
      margin-top: 15px;
      background: rgba(248, 248, 248, 1);
      border: 1px solid #f2f2f2;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;

      .other_info_item {
        width: calc(50% - 20px);
        height: 40px;
        line-height: 40px;
        padding-left: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a3a3a3;

        .left_width {
          display: inline-block;
          width: 86px;
        }
      }
    }
  }

  .details_other {
    width: 290px;
    margin-left: 15px;

    .details_other_title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #000000;
    }

    .details_other_product {
      width: 254px;
      height: 380px;
      margin-top: 15px;
      padding: 7px 17px;
      background: rgba(248, 248, 248, 1);
      border-radius: 5px;
      overflow: auto;

      .product_item {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #454545;
        cursor: pointer;

        .product_item_name {
          display: inline-block;
          flex: 1;
          margin-right: 4%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }

        .product_item_code {
          display: inline-block;
          width: 50%;
          text-align: left;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #575757;
        }
      }

      .product_item:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
