// 主页接口
import http from '../request.js'

export default {
  // 获取商品列表
  // getGoodsList: data => {
  //   return http({
  //     url: '/api/goods/getstorebycode',
  //     data
  //   })
  // },

  // 获取商品列表
  getGoodsList: data => {
    return http({
      url: '/api/goods/list_server',
      data
    })
  },
  
}
