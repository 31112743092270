<template>
  <div id="app">
    <!-- <transition name="fade" mode="out-in">
      <keep-alive include="home,smoke,wine,release">
        <router-view />
      </keep-alive>
    </transition> -->

    <keep-alive include="home,smoke,wine,release,export">
      <router-view />
    </keep-alive>

    <img class="logo_code code1" src="./assets/img/code1.png" alt="" />
    <img class="logo_code code2" src="./assets/img/code2.png" alt="" />

    <el-backtop></el-backtop>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const windowUrl = window.location.hostname;
    const protocol = window.location.protocol;

    if (
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
        sUserAgent
      )
    ) {
      if (windowUrl.indexOf("www.chajiuqu.com") > -1) {
        window.location.href = "https://m.chajiuqu.com";
      }
    } else {
      if (windowUrl.indexOf("m.chajiuqu.com") > -1) {
        window.location.href = "https://www.chajiuqu.com";
      } else if (
        windowUrl.indexOf("www.chajiuqu.com") > -1 &&
        protocol == "http:"
      ) {
        window.location.href = "https://www.chajiuqu.com";
      }
    }
  },
  methods: {},
};
</script>

<style lang="scss" scope>
html {
  overflow-y: scroll;
}
#app {
  text-align: center;
  color: #2c3e50;

  .logo_code {
    width: 126px;
    height: 252px;
    display: block;
    position: fixed;
    top: calc(50vh - 126px);
  }

  .code1 {
    left: 7px;
  }

  .code2 {
    right: 7px;
  }
}

@media screen and (max-width: 1370px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  .code1 {
    left: -63px !important;
    cursor: pointer;
  }

  .code2 {
    right: -63px !important;
    cursor: pointer;
  }

  .code1:hover {
    transform: translateX(63px);
  }

  .code2:hover {
    transform: translateX(-63px);
  }
}

.fade-enter,
.fade-leave-to {
  transform: scale(0.5, 0.5);
}
.fade-enter-to,
.fade-leave {
  transform: scale(1, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
