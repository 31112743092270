import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/icon.css'

// 全局css
import './style/index.scss'

// 全局组件自动注册
import './components/autoRegister'

// 阿里图标
import './assets/iconFont/iconfont.css'

// api挂载
import api from '@/api'

import * as filters from './filters' // 全局过滤器
import globalFun from './utils'

// 组件注册
Vue.use(ElementUI)

// 挂载全局方法
Vue.prototype.$api = api.module
Vue.prototype.$globalFun = globalFun

// 挂载全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
//关闭Vue的生产提示
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    //在初始化阶段前
    Vue.prototype.$bus = this //配置全局总线
  },
}).$mount('#app')
