<!--
 * @Author: 云逸 1832583130@qq.com
 * @Date: 2023-04-28 11:37:11
 * @LastEditors: 云逸 1832583130@qq.com
 * @LastEditTime: 2023-05-23 16:33:32
 * @FilePath: \tea-admine:\project\tea-web\src\components\AllFooter\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="footer">
    <!-- <div>
      <img class="footer_img" src="../../assets/img/footer.png" alt="" />
    </div> -->

    <div class="login_footer">
      <div class="customer_service">客服微信:13417003906</div>
      <div>
        <!-- eslint-disable no-irregular-whitespace -->
        版权所有：汕头市茶三酒四电子商务有限公司　技术支持：汕头市茶三酒四电子商务有限公司
      </div>
      <div style="margin-top: 7px">
        ICP备案号：
        <a style="color: blue;" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021091522号-1</a> 
        工商注册号：440500000170158
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AllFooter',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  padding: 30px 0;
  // background: url('../../assets/img/footer.png') no-repeat;
  // background-size: 1920px 206px;

  .footer_img {
    width: 100%;
    display: block;
  }

  .login_footer {
    width: 100%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #bebebe;

    .customer_service {
      font-size: 20px;
      color: #7e7e7e;
      margin-top: 13px;
      margin-bottom: 14px;
    }
  }
}
</style>
