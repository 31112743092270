<template>
  <div>
    <div :id="id"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'DetailsCharts',

  props: {
    id: {
      type: String,
      default: 'charts',
    },
    dataList: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'line',
    },
    width: {
      type: Number,
      default: 1080,
    },
    height: {
      type: Number,
      default: 300,
    },
    color1: {
      type: String,
      default: 'rgba(91, 143, 249)',
    },
    color2: {
      type: String,
      default: 'rgba(255, 255, 255, 3)',
    },
  },

  data() {
    return {
      myChart: null,
    }
  },

  watch: {
    dataList: function (newVal) {
      // console.log(newVal)
      this.setEcharts(newVal.xData, newVal.yData, this.color1, this.color2)
    },
  },

  methods: {
    // 走势图
    setEcharts(xData, yData, color1, color2) {
      if (!document.getElementById(this.id)) return

      if (this.myChart) {
        this.myChart.dispose()
      }

      this.myChart = echarts.init(document.getElementById(this.id), null, {
        width: this.width,
        height: this.height,
      })

      let option = {
        title: {
          show: false,
          text: 'ECharts图表',
        },
        legend: {
          show: false,
        },
        grid: {
          left: 60,
          top: 40,
          right: 40,
          bottom: 30,
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: xData,
          axisLabel: {
            interval: 0,
            fontSize: 10,
            rotate: this.type == 'line' ? 30 : 0,
          },
        },
        yAxis: {
          type: 'value',
          max: this.$globalFun.max(yData) + 2,
          min: this.$globalFun.min(yData) - 8,
        },
        color: [
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: color1, // 0% 处的颜色
              },
              {
                offset: 1,
                color: color2, // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        ],
        tooltip: {},
        series: [
          {
            data: yData,
            type: this.type,
            symbol: 'circle',
            symbolSize: 10,
            itemStyle: {
              // color: '#5B8FF9',
              color: color1,
            },
            areaStyle: {
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              shadowBlur: 0,
              shadowOffsetY: 0,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  // color: 'rgba(91, 143, 249)',
                  color: color1,
                },
                {
                  offset: 1,
                  // color: 'rgba(255,255,255,0.3)',
                  color: color2,
                },
              ]),
            },
          },
        ],
      }

      option && this.myChart.setOption(option)
    },
  },
}
</script>

<style lang="scss" scoped></style>
