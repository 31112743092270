import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/modules/user.js'
// import Cookies from 'vue-cookies'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // tokens: Cookies.get('tokens') || '',
    tokens: localStorage.getItem('tokens') || '',
    info: JSON.parse(localStorage.getItem('info') || null),

    homeScroll: 0,
  },
  mutations: {
    // 初始化数据
    RESET_STATE: (state) => {
      // Cookies.remove('tokens')
      localStorage.removeItem('tokens')
      localStorage.removeItem('info')

      state.tokens = ''
      state.info = ''
    },
    // 存储token
    SET_TOKEN: (state, token) => {
      state.tokens = token
      // Cookies.set('tokens', token, '1m')
      localStorage.setItem('tokens', token)
    },
    // 存储info
    SET_INFO: (state, info) => {
      state.info = info
      localStorage.setItem('info', JSON.stringify(info))
    },

    // 首页滚动
    SET_SCROLL: (state, val) => {
      state.homeScroll = val
    },
  },
  actions: {
    // 登录
    login({ commit }, data) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data: res } = await api.login(data)
          console.log('登录成功', res)

          if (!res) {
            resolve('error')
          } else {
            commit('SET_TOKEN', res.Token)
            commit('SET_INFO', res)
            resolve(res)
          }
        } catch (e) {
          reject(e)
        }
      })
    },

    // 登出
    async logout({ commit }, data) {
      data && (await api.logout())

      // 清缓存
      commit('RESET_STATE')
    },
  },
  modules: {},
})
