<template>
  <div class="merchants_release_hot">
    <div class="hot">
      <div
        class="hot_item"
        v-for="item in dataList"
        :key="item.Id"
        @click="goDetails(item.Id)"
      >
        <img
          class="img"
          v-if="item.image"
          :src="
            item.image[0].Image.indexOf('cosgz') > -1
              ? item.image[0].Image.replace('cosgz', 'cos.ap-guangzhou') +
                '/pc_img'
              : item.image[0].Image
          "
          alt=""
        />
        <img class="img" v-else src="../../assets/img/noImg.png" alt="" />
        <div class="item_text">
          <div class="text">
            {{ item.Title }}
          </div>
          <div class="read">
            <div>{{ item.BrowseCount || 0 }}阅读</div>
            <div style="margin-left: 20px">
              {{ new Date(item.UpdateTime) | parseTime('{m}月{d}日') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MerchantsReleaseHot',
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },

  methods: {
    goDetails(id) {
      this.$emit('goDetails', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.merchants_release_hot {
  width: 100%;
  text-align: left;

  .hot {
    width: 100%;

    .hot_item {
      width: 100%;
      margin-top: 17px;
      display: flex;
      cursor: pointer;

      .img {
        width: 106px;
        height: 106px;
        display: block;
      }

      .item_text {
        margin-left: 8px;
        flex: 1;

        .text {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 25px;
          color: #2d2d2d;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          white-space: pre-line;
        }

        .read {
          margin-top: 6px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 24px;
          color: #9a9a9a;
          display: flex;
        }
      }
    }
  }
}
</style>
