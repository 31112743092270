<template>
  <div class="merchants_release">
    <div class="tabs">
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-if="!isAm" label="商品展示" name="商品展示">
        </el-tab-pane>

        <el-tab-pane label="供需信息" name="供需信息"> </el-tab-pane>

        <el-tab-pane v-if="!isAm" label="商家简介" name="商家简介">
        </el-tab-pane>
      </el-tabs> -->

      <div class="tabs_list">
        <div
          class="list_item"
          :class="activeName == '商品展示' ? 'list_item_active' : ''"
          @click="activeName = '商品展示'"
          v-if="!isAm"
        >
          商品展示
          <transition name="fade">
            <div v-if="activeName == '商品展示'" class="active_item_bar"></div>
          </transition>
        </div>
        <div
          class="list_item"
          :class="activeName == '供需信息' ? 'list_item_active' : ''"
          @click="activeName = '供需信息'"
        >
          供需信息
          <transition name="fade">
            <div v-if="activeName == '供需信息'" class="active_item_bar"></div>
          </transition>
        </div>
        <div
          class="list_item"
          :class="activeName == '商家简介' ? 'list_item_active' : ''"
          @click="activeName = '商家简介'"
          v-if="!isAm"
        >
          商家简介
          <transition name="fade">
            <div v-if="activeName == '商家简介'" class="active_item_bar"></div>
          </transition>
        </div>
      </div>

      <div class="article" v-show="activeName == '供需信息'">
        <div
          class="article_item"
          v-for="(item, index) in dataList"
          :key="item.Id"
        >
          <div class="item_name" @click="goDetails(item.Id)">
            <img
              class="item_head_img"
              :src="item.StoreHeadImage || defaultPic"
              alt=""
            />
            <span>{{ item.StoreName || '--' }}</span>
          </div>
          <div class="item_text" @click="goDetails(item.Id)">
            {{ item.Title }}
          </div>
          <div class="item_img" v-if="item.image" @click="goDetails(item.Id)">
            <img
              class="img"
              v-for="items in item.image.slice(0, 6)"
              :key="items.Id"
              :src="
                items.Image.index0f('cosgz') > -1
                  ? items.Image.replace('cosgz', 'cos.ap-guangzhou') + '/pc_img'
                  : items.Image
              "
              alt=""
              srcset=""
            />
          </div>
          <div class="item_foot">
            <div>{{ new Date(item.UpdateTime) | formatTime }}</div>
            <div
              style="color: #2280ff; cursor: pointer"
              @click="sendMsg(index)"
            >
              给Ta留言
            </div>
            <img
              v-if="codeImgCurrent == index"
              class="item_foot_img"
              src="../../assets/img/code3.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>

      <div v-show="activeName != '供需信息' || dataList.length == 0">
        <img
          style="width: 330px; height: 367px"
          src="../../assets/img/code3.png"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MerchantsRelease',
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },

    isAm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: '供需信息',

      defaultPic: 'https://api.chajiuqu.com/uploadImage/img/defaultPict.png',

      codeImgCurrent: null,
    }
  },

  methods: {
    handleClick() {},

    sendMsg(index) {
      if (this.codeImgCurrent == index) {
        this.codeImgCurrent = null
        return
      }
      this.codeImgCurrent = index
    },

    goDetails(id) {
      this.$router.push({
        path: '/merchants/details',
        query: {
          id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.merchants_release {
  width: 100%;

  .tabs {
    /deep/ .el-tabs__item {
      font-size: 18px;
      color: #222222;
    }

    /deep/ .el-tabs__item.is-active {
      color: #d83e26;
    }

    /deep/ .el-tabs__active-bar {
      background-color: #d83e26;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(100%);
    opacity: 0;
  }

  .tabs_list {
    width: 100%;
    display: flex;
    border-bottom: 2px solid #e4e7ed;
    font-size: 18px;
    color: #222222;
    margin-bottom: 15px;

    .list_item {
      margin-left: 40px;
      padding-bottom: 6px;
      cursor: pointer;
    }

    .list_item_active {
      color: #d83e26;
      position: relative;

      .active_item_bar {
        width: 100%;
        height: 2px;
        background-color: #d83e26;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .list_item:first-child {
      margin-left: 0;
    }
  }

  .article {
    width: 100%;

    .article_item {
      width: 100%;
      margin-top: 20px;
      padding-bottom: 8px;
      border-bottom: 5px solid #f2f2f2;
      text-align: left;

      .item_name {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #717171;
        display: flex;
        align-items: center;

        .item_head_img {
          width: 25px;
          height: 25px;
          display: block;
          margin-right: 6px;
          border-radius: 50%;
        }
      }

      .item_text {
        margin-top: 10px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 28px;
        color: #272727;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .item_img {
        margin-top: 5px;
        width: 345px;
        display: flex;
        flex-wrap: wrap;

        .img {
          width: 110px;
          height: 110px;
          display: block;
          margin-top: 5px;
          margin-right: 5px;
        }
      }

      .item_foot {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #9a9a9a;
        position: relative;

        .item_foot_img {
          width: 260px;
          display: block;
          position: absolute;
          bottom: 24px;
          right: 0;
        }
      }
    }

    .article_item:first-child {
      margin-top: 0;
    }
  }
}
</style>
