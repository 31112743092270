import Vue from "vue"
import store from "../store"
import VueRouter from "vue-router"
// import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
	// 首页
	// {
	//   path: '/',
	//   redirect: 'home',
	//   component: () => import('../views/tabPage'),
	//   meta: {
	//     keepAlive: false,
	//   },
	//   children: [
	//     {
	//       path: 'home',
	//       name: 'home',
	//       component: () => import('../views/tabPage/home.vue'),
	//       meta: {
	//         keepAlive: true,
	//       },
	//     },
	//     {
	//       path: 'smoke',
	//       name: 'smoke',
	//       component: () => import('../views/tabPage/smoke.vue'),
	//       meta: {
	//         keepAlive: true,
	//       },
	//     },
	//     {
	//       path: 'wine',
	//       name: 'wine',
	//       component: () => import('../views/tabPage/wine.vue'),
	//       meta: {
	//         keepAlive: true,
	//       },
	//     },
	//     {
	//       path: 'release',
	//       name: 'release',
	//       component: () => import('../views/tabPage/release.vue'),
	//       meta: {
	//         keepAlive: true,
	//       },
	//     },
	//   ],
	// },

	// tabPage
	{
		path: "/",
		redirect: "home",
		component: () => import("../views/tabPage/home.vue"),
		meta: {
			keepAlive: true
		}
	},
	{
		path: "/home",
		name: "home",
		component: () => import("../views/tabPage/home.vue"),
		meta: {
			keepAlive: true
		}
	},
	{
		path: "/smoke",
		name: "smoke",
		component: () => import("../views/tabPage/smoke.vue"),
		meta: {
			keepAlive: true
		}
	},
	{
		path: "/wine",
		name: "wine",
		component: () => import("../views/tabPage/wine.vue"),
		meta: {
			keepAlive: true
		}
	},
	{
		path: "/release",
		name: "release",
		component: () => import("../views/tabPage/release.vue"),
		meta: {
			keepAlive: true
		}
	},
	{
		path: "/export",
		name: "export",
		component: () => import("../views/tabPage/export.vue"),
		meta: {
			keepAlive: true
		}
	},

	// 登录
	{
		path: "/login",
		name: "login",
		component: () => import("../views/login/index.vue")
	},
	// 报价详情
	{
		path: "/details",
		name: "details",
		component: () => import("../views/details/index.vue")
	},
	// 个人
	{
		path: "/merchants/index",
		name: "merchantsIndex",
		component: () => import("../views/merchants/index.vue")
	},
	// 文章详情
	{
		path: "/merchants/details",
		name: "merchantsDetails",
		component: () => import("../views/merchants/details.vue")
	}
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	// console.log(to, from)
	let isLogin = store.state.tokens
	if (
		!isLogin &&
		to.path !== "/" &&
		to.path !== "/home" &&
		to.path !== "/login"
	) {
		next("login")
	} else if (
		isLogin &&
		to.path !== "/" &&
		to.path !== "/home" &&
		to.path !== "/login"
	) {
		next()
	} else {
		next()
	}
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch((err) => err)
}

export default router
